import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./CategoryDetails.css";
import { Link, useLocation, useParams} from 'react-router-dom';
import {useState, useEffect} from 'react';
import Image from 'react-bootstrap/Image';
import Category from "../category";

type CategoryItem = {
  text: string;
  img_src: string;
  description: string;
}

type Catergory = {
  title: string;
  id: string;
  products: CategoryItem[];
}

function CategoryDetails() {
  const [data,setData]=useState<Catergory[] | undefined>(undefined);

  const location = useLocation();
  //const propsData = location.state as any;
  const propsData = useParams();
  console.log(propsData.id)

  const getData=()=>{
    fetch('/products.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        setData(myJson)
      });
  }

  useEffect(()=>{
    console.log("useEffect");
    getData()
    const element = document.getElementById("service");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
  },[])

  let filterdata:Catergory[] = [];
  try {
    filterdata = data? data.filter((x:Catergory) => x.id === propsData.id): [] as Catergory[];
  } catch (error) {
  }
  
  return (
    <div id="service" className="service">
      <Container>
        <Row className="row">
        <Col xl={6} lg={6} md={6} sm={6} ><div className="titlepage">
           <h2>{filterdata[0] != null ? filterdata[0].title:""}</h2>  
         </div>  
         </Col>
         </Row>
         <Row className="row">
         {data && filterdata[0].products.map((item:CategoryItem, index:number) => {
          return [<Col xl={6} lg={6} md={6} sm={6} ><div className="product-box"><figure><Image src={item!= null ? item.img_src:""}  /> </figure>
          <h3>{item.description}</h3>
            <p dangerouslySetInnerHTML={ {__html: item.text }}/></div></Col>,]
         })}
  {/*        <div className="service-box">
          <Image src={filterdata[0] != null ? filterdata[0].img_src:""} width={"300"} />  
         </div>
         <div dangerouslySetInnerHTML={ {__html: filterdata[0] != null ? filterdata[0].text:"" }}/> */}

        </Row>
      </Container>
    </div>
  );
}



export default CategoryDetails;
