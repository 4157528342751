import axios from 'axios';

export async function sendContactUs(formInput: any)
{
    const emailUrl = String(process.env.REACT_APP_EMAIL_POST_URL);
    const message = "Contact us details\nName: " + formInput.formName
    + "\nEmail: " + formInput.formEmail
    + "\nPhone: " + formInput.formPhone
    + "\nDetails: " + formInput.formDetails;
    axios({
        method:"POST",
        url: process.env.REACT_APP_EMAIL_POST_URL,
        data:
        {
            message:message
        }
    
    }).then((response)=>{})
    .catch((err)=>{
        if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(err.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', err.message);
          }
          console.log(err.config);
    });
}
