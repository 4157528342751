import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Header.css';
import {LinkContainer} from 'react-router-bootstrap'

function CollapsibleHeader() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" id="header">
      <Container>
          <Navbar.Brand href="/">Red Oak Decor</Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="main_menu">
          <Nav className="me-auto">
            <Nav.Link href="/#service">Products</Nav.Link>
            <Nav.Link href="/#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleHeader;