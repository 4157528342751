
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Footer.css';

function Footer() {
return (
    <div className="footer">
    <Container>
        <Row>
            <Col sm={12}md={6} lg={2}>
                     <div className="address">
                        <h3>Social Media </h3>
                        <ul className="contant_icon">
                         
                           <li><a href="https://www.facebook.com/profile.php?id=100095071989634"><img src="/icon/fb.png" alt="icon" /></a></li>
                           <li><a href="https://www.instagram.com/redoakdecor_ca/"><img src="/icon/instagram.png" alt="icon" /></a></li>
                        </ul>
                     </div>
                </Col>
        </Row>
    </Container>

    </div>

        );
{/*             <div className="container">
               <div className="row">
                  <div className="col-lg-2 col-md-6 col-sm-12 width">
                     <div className="address">
                        <h3>Address</h3>
                        <i><img src="icon/3.png" />Locations</i>
                     </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12 width">
                     <div className="address">
                        <h3>Menus</h3>
                        <i><img src="icon/2.png" />Locations</i>
                     </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12 width">
                     <div className="address">
                        <h3>Useful Linkes</h3>
                        <i><img src="icon/1.png">Locations</img></i>
                     </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12 width">
                     <div className="address">
                        <h3>Social Media </h3>
                        <ul className="contant_icon">
                           <li><img src="icon/fb.png" alt="icon" /></li>
                           <li><img src="icon/tw.png" alt="icon" /></li>
                           <li><img src="icon/lin(2).png" alt="icon" /></li>
                           <li><img src="icon/instagram.png" alt="icon" /></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 width">
                     <div className="address">
                        <h3>Newsletter </h3>
                        <input className="form-control" placeholder="Enter your email" type="type" name="Enter your email" />
                        <button className="submit-btn">Subscribe</button>
                     </div>
                  </div>
               </div>
            </div>
            <div className="copyright">
               <p>Copyright 2019 All Right Reserved By <a href="https://html.design/">Free html Templates</a></p>
            </div> */}
     


         }
         export default Footer;