import Carousel from 'react-bootstrap/Carousel';
import './Carousel.css';

function UncontrolledExample() {
  return (
    <Carousel id='myCarousel' variant="dark">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="images/IMG_2939.jpeg"
          alt="First slide"
        />

      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="images/banner/banner2.jpeg"
          alt="Third slide"
        />


      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="images/banner/banner3.jpeg"
          alt="Third slide"
        />

      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;