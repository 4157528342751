import { Container } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './ContactUs.css';
import Button from '@mui/material/Button';
import React, { ChangeEvent } from 'react';
import {sendContactUs} from '../services/email';

type ContactUsState = {
    name: string,
    phone: string, 
    email: string, 
    details: string,
    formErrors: {name:string, email:string, phone:string, details:string},
    formValid: boolean,
    submitted: boolean;

}

class ContactUs extends React.Component<{}, ContactUsState> {


    constructor (props: any ) {
        super(props);
        this.state = {
          name: '',
          phone: '',
          email: '',
          details: '',
          formErrors: {name:'', email: '', phone: '', details:''},
            formValid: false,
            submitted: false

        }
      }
    

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            let target = event.target as HTMLFormElement
            const data = {
                formName: target.formName.value,
                formPhone: target.formPhone.value,
                formEmail: target.formEmail.value,
                formDetails: target.formDetails.value
            }
            console.log(data)
             sendContactUs(data);
             this.setState({submitted:true});

        };

    
    handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
       const name = event.target.name;
        const value = event.target.value;
        this.setState({[name]: value} as any);
        if (this.state.formValid === false)
            this.validateField(name, value);
    };

    handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value;
        this.validateField(name, value)
    }

    validateField(fieldName: string, fieldValue: string) {
        let formErrors = this.state.formErrors;
        let formValid = this.state.formValid;
        switch(fieldName) {
            case 'name': {
                formErrors.name = (fieldValue !== "") ? "" : "Please Enter a name";
                break;
            }
            case 'phone': { 
                const phoneValid = fieldValue.match(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}/g);
                formErrors.phone  = phoneValid ? '' : "Phone number is invalid";
                break;
            }
            case 'email': {
                const emailValid = fieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                formErrors.email = emailValid ? '' : "Please enter a valid email";
                break;
            }
            case 'details': {
                formErrors.details = (fieldValue !== "") ? "" : "Let us know how we can help";
                break;
            }
           
        }
        if (formErrors.name === "" && formErrors.phone === "" && formErrors.email === "" && formErrors.details === "")
            formValid = true;
        else
            formValid = false;
        this.setState({formErrors: formErrors,
                        formValid: formValid});

            
    }


    render(){
        return(
        <div id="contact" className="contact">
        <Container fluid className="contact_us">
        <div className="titlepage">
                         <h3>Contact Us</h3>
        </div>
        <div className='contact_form' style={this.state.submitted ?{} :  { display: 'none' }} >
            Form Submitted
        </div>
        <div className='contact_form' style={this.state.submitted ?  { display: 'none' } : {}} >
            <Form onSubmit={this.handleSubmit} >
            <Form.Group className="mb-3" controlId="formName">
                <Form.Control placeholder="Name" onChange={this.handleUserInput} isInvalid={ !!this.state.formErrors.name} onBlur={this.handleBlur} name="name"value={this.state.name}/>
                <Form.Control.Feedback type='invalid'>
                           { this.state.formErrors.name }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPhone" >
                <Form.Control  placeholder="Phone" isInvalid={ !!this.state.formErrors.phone} onBlur={this.handleBlur} name="phone" onChange={this.handleUserInput} value={this.state.phone} />
                <Form.Control.Feedback type='invalid'>
                           { this.state.formErrors.phone }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail" >
                <Form.Control  placeholder="Email" onChange={this.handleUserInput} onBlur={this.handleBlur} name="email" isInvalid={ !!this.state.formErrors.email} value={this.state.email}/>
                <Form.Control.Feedback type='invalid'>
                           { this.state.formErrors.email }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDetails" >
                <Form.Control as="textarea" placeholder="Details" onChange={this.handleUserInput} onBlur={this.handleBlur} name="details" isInvalid={ !!this.state.formErrors.details} value={this.state.details}/>
                <Form.Control.Feedback type='invalid'>
                           { this.state.formErrors.details }
                </Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" className="send" disabled={!this.state.formValid}>
                Submit
            </Button>
            </Form>
            </div>
        </Container>
        </div>
        );
    }
}

export default ContactUs;
/*{
/* function ContactUs() {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let target = event.target as HTMLFormElement
        const data = {
            formName: target.formName.value,
            formPhone: target.formPhone.value
        }
        console.log(data)
        
        
    };
}
} */
    
    



