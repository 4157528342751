import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./BoothPrintDetails.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link, useLocation, useParams} from 'react-router-dom';
import {useState, useEffect} from 'react';
import Image from 'react-bootstrap/Image';
import Category from "../category";

type CategoryItem = {
  text: string;
  img_src: string;
  description: string;
}

type Catergory = {
  title: string;
  id: string;
  products: CategoryItem[];
}

function CategoryDetailsBooth() {
  const [data,setData]=useState<Catergory[] | undefined>(undefined);

  const location = useLocation();
  //const propsData = location.state as any;
  

  useEffect(()=>{
    const element = document.getElementById("service");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
  },[])


  return (

<div>
      <section className="features">
         <Container>

        <div className="section-title">
          <h2>Photo Templates</h2>
          <p>Templates from our guests available in color, glam or your choice of filters</p>
        </div>
        <Row>
          <Col md={4} className="pt-4 picture-box">
              <img src="/images/phototemplates/1.jpg" className="img-fluid" alt=""></img>
          </Col>
          <Col md={7} className="pt-4">
          <h3>Classic Postcard</h3>
              <p className="fst-italic">
                The classic 4x6 picture available in glam or color.
              </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="pt-4 picture-box">
              <img src="/images/phototemplates/2.jpg" className="img-fluid" alt=""></img>
          </Col>
          <Col md={7} className="pt-4">
          <h3>Vertial 4 shots</h3>
              <p className="fst-italic">
                Our Most popular...<br />
                4 shots per a session and goes great with the glam filter.
              </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="pt-4 picture-box">
              <img src="/images/phototemplates/3.jpg" className="img-fluid" alt=""></img>
          </Col>
          <Col md={7} className="pt-4">
          <h3>Elegent Postcard</h3>
              <p className="fst-italic">
                Crisp clean border with a simple design.
              </p>
          </Col>
        </Row>








        </Container>
    </section>

</div>
  );
}



export default CategoryDetailsBooth;
