import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Carousel from './components/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Categories from './components/Categories';
import CategoryDetails from './components/CategoryDetails';
import CategoryDetailsBooth from './components/CategoryDetailsBooth';
import BoothPrintDetails from './components/BoothPrintDetails';

import {
   Router,
  Routes,
  Route,
  useLocation,
  Link
} from 'react-router-dom';

function App() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change


  return (
    <div className="App">
      

   
      <Header />
    <Routes>
  <Route path="/" element={<><Carousel /><Categories /></>} />
  <Route path="/category/booth" element={<><CategoryDetailsBooth /></>}/>
  <Route path="/booth/print" element={<BoothPrintDetails />} /> 
  <Route path="/category/:id" element={<CategoryDetails />} />
  
  </Routes>


      <ContactUs></ContactUs>
      <Footer></Footer>
     {/*  <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
