import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Categories.css";
import { Link } from 'react-router-dom';

function Categories() {
  return (
    <div id="service" className="service">
      <Container>
        <Row>
          <Col md={12}>
            <div className="titlepage">
              <h2>Products </h2>
              <span>
                These are some of the products we offer. Don't see what you need
                - ask us if we can build it.
              </span>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm={12} md={4} lg={4} xl={4} className="service-item">
            <div className="service-box">
              <figure>
                <a href="images/cart.jpg" className="fancybox" rel="ligthbox">
                  <img
                    src="images/cart.jpg"
                    className="zoom img-fluid "
                    alt=""
                  />
                </a>
                <span className="hoverle">
                  <Link to='/category/cart' className="fancybox" rel="ligthbox" state={{id:"cart"}}>Candy Cart</Link>
                </span>
              </figure>
            </div>
          </Col>
          <Col sm={12} md={4} lg={4} xl={4} className="service-item">
            <div className="service-box">
              <figure>
                <a href="images/photobooth2.jpg" className="fancybox" rel="ligthbox">
                  <img
                    src="images/photobooth2.jpg"
                    className="zoom img-fluid "
                    alt=""
                  />
                </a>
                <span className="hoverle">
                <Link to='/category/booth' className="fancybox" rel="ligthbox" state={{id:"booth"}}>Photo Booth</Link>
            
                </span>
              </figure>
            </div>
          </Col>
          <Col sm={12} md={4} lg={4} xl={4} className="service-item">
            <div className="service-box">
              <figure>
                <a href="images/arch1.jpg" className="fancybox" rel="ligthbox">
                  <img
                    src="images/arch1.jpg"
                    className="zoom img-fluid "
                    alt=""
                  />
                </a>
                <span className="hoverle">
                <Link to='/category/decor' className="fancybox" rel="ligthbox" state={{id:"decor"}}>Decor</Link>
            
                </span>
              </figure>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Categories;
